body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  height: 100vh;
  background-color: white;
}

nav {
  display: flex;
  background-color: black; 
  font-weight: 700;
  font-size: 16px;
}

nav img {
  height: 28px;
  padding-top: 10px;
  padding-left: 20px;
}

nav ul {
  list-style-type: none;
}

nav ul li {
  float: left;
}

nav ul li a {
  padding-right: 20px;
  text-decoration: none;
  color: white;
}

nav ul li a:hover, nav ul li a:focus {
  color: #61DAFB;
}

main {
  padding: 20px 0px 0px 20px;
  color: black;
}

main h1 {
  margin: 0;
  font-size: 36px;
  color: #61DAFB;
}

main li {
  font-size: 16px;
  padding-block: 5px;
  color: grey;
  font-weight: 700;
}

main li::marker {
    font-size: 1.4rem;
    color: #61DAFB;
}